<template>
  <div v-loading="loading">
    <div v-show="(ubicacion != 'Error' && ubicacion != 'Cargando...') || requerirubicacion == 'false'">
      <el-main id="mainpinpad"
        v-show="mode == 'dni' || solicitarpin == 'true' && (mode == 'pin' || mode == 'cambiarPin' || mode == 'crearPin')"
        :class="{ 'apply-shake': shake }">
        <h2 :class="shake ? 'instrucciones red' : 'instrucciones'">{{ instrucciones }}</h2>
        <!-- <img class="avatar" v-if="mode == 'pin'" style="" :src="`https://www.gravatar.com/avatar/${datos.email}`" /> -->
        <div id="contenedorSalida">
          <div v-for="(salida, s) in salida" :key="s">{{ mode == "pin" || (mode == "cambiarPin" || mode == 'crearPin') ?
    "*"
    : salida }}</div>
        </div>
        <ul class="contenedorNumeros">
          <li v-for="(boton, b) in botones" :key="b" @click="anadirContenido(boton)" :class="boton.tipo">
            <i v-if="boton.icon" :class="boton.icon"></i>
            <p v-else>{{ boton.valor }}</p>
          </li>
        </ul>
        <div v-if="mode == 'dni' && selcentro" class="dni-switch">
          <el-switch v-model="checkGuardar" active-text="Guardar DNI para iniciar sesión automáticamente" />
        </div>
        <div v-if="selcentro && mode == 'pin' && (optionSelected == '' || optionSelected == 'Reanudacion')"
          class="dni-switch">
          <el-switch v-model="teletrabajo" active-text="Fichar entrada como teletrabajo" />
        </div>
        <div v-if="mode == 'pin' && instrucciones == 'Introduce el PIN para continuar'" class="dni-switch">
          <p @click="cambiarModo('olvidoPin')" :style="`cursor: pointer;`"><b><font-awesome-icon
                :icon="['fas', 'lock']" />
              ¿Olvidaste tu PIN?</b></p>
        </div>
      </el-main>
      <el-main id="solicitarfoto" v-show="mode == 'solicitarFoto'" :class="{ 'apply-shake': shake }">
        <!-- <img class="avatar" v-if="mode == 'pin'" style="" :src="`https://www.gravatar.com/avatar/${datos.email}`" /> -->
        <div>
          <div style="position: relative; text-align: center; color: white; font-size: 1.5rem; padding-top: 10px;">
          </div>
          <video v-show="!photoDataUrl" ref="video" autoplay playsinline style="max-width: 100%; height: auto;"></video>

          <!-- Mostrar la foto recién tomada -->
          <img v-show="photoDataUrl" :src="photoDataUrl" alt="Captured Photo" style="width: 100%; max-height: 100%;">

          <div style="position: relative;">
            <p style="width: 100%">
              <el-button v-if="!photoDataUrl" style="    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: none;
    border-width: 5px;
    font-size: 2rem;" @click="takePhoto" type="success" icon="el-icon-camera" circle></el-button>

              <span style="position: absolute; left: 50%; transform: translateX(-50%); bottom: 30px;">
                <el-button v-if="photoDataUrl" style="font-size: 2rem;" @click="initCamera()" type="danger"
                  icon="el-icon-close" circle></el-button>
                <el-button v-if="photoDataUrl" style="font-size: 2rem;" @click="aceptarFoto()" type="success"
                  icon="el-icon-check" circle></el-button>
              </span>
            </p>
          </div>
        </div>
        <canvas ref="canvas" style="width: 100%; max-height: 100%; display: none;"></canvas>
      </el-main>


      <el-main id="contenedorajustes" style="text-align:center;" v-if="mode == 'olvidoPin'"
        :class="{ 'apply-shake': shake }">
        <h2 style="margin-bottom: 10px;">¿Olvidaste tu PIN?</h2>
        <img :src="require('@/assets/olvidar-pin.png')" style="max-width: 40%; margin:auto;" />
        <h2 style="margin-bottom: 10px; text-align:justify;">Vamos a recuperarlo</h2>
        <p style="text-align: justify;">Introduce tu correo electrónico a continuación. Si coincide con el registrado,
          recibirás un enlace para crear tu nuevo PIN.</p>
        <el-input type="email" placeholder="Introduce tu correo electrónico" v-model="emailRecover"></el-input>
        <div>
          <div @click="cambiarModo('pin');" :style="`background: ${datoscolor.colorfondobotones};`">
            <el-button :style="`background-color: ${datoscolor.colorbotones}; border-color: ${datoscolor.colorbotones}`"
              type="primary" circle><font-awesome-icon style="font-size:1.5rem;" :icon="['fas', 'arrow-left']" />
            </el-button> <b :style="`color: ${datoscolor.colorbotones}`">Volver atrás</b>
          </div>
          <div @click="recuperarPin(emailRecover);" style="background: #a3cb3826;">
            <el-button style="background-color: #A3CB38; border-color: #A3CB38" type="primary" circle><font-awesome-icon
                style="font-size:1.5rem;" :icon="['fas', 'lock']" />
            </el-button> <b style="color: #A3CB38">Recuperar PIN</b>
          </div>
        </div>
      </el-main>
      <el-main id="contenedorajustes" style="text-align:center;" v-if="mode == 'recovery'"
        :class="{ 'apply-shake': shake }">
        <h2 style="margin-bottom: 10px;">Recupera tu PIN</h2>
        <img :src="require('@/assets/olvidar-pin.png')" style="max-width: 40%; margin:auto;" />
        <p style="text-align: justify;">Introduce el código que has recibido en tu correo electrónico. Los códigos
          tienen
          una duración de 30 minutos y son de un único uso.</p>
        <el-input type="email" placeholder="Introduce el código" v-model="tokenRecover"></el-input>
        <div>
          <div @click="cambiarModo('logout');" :style="`background: ${datoscolor.colorfondobotones};`">
            <el-button :style="`background-color: ${datoscolor.colorbotones}; border-color: ${datoscolor.colorbotones}`"
              type="primary" circle><font-awesome-icon style="font-size:1.5rem;" :icon="['fas', 'arrow-left']" />
            </el-button> <b :style="`color: ${datoscolor.colorbotones}`">Volver atrás</b>
          </div>
          <div @click="validarTokenRecover();" style="background: #a3cb3826;">
            <el-button style="background-color: #A3CB38; border-color: #A3CB38" type="primary" circle><font-awesome-icon
                style="font-size:1.5rem;" :icon="['fas', 'lock']" />
            </el-button> <b style="color: #A3CB38">Recuperar PIN</b>
          </div>
        </div>
      </el-main>
      <el-main id="contenedorajustes" style="text-align:center;" v-if="mode == 'nuevoPin'"
        :class="{ 'apply-shake': shake }">
        <h2 style="margin-bottom: 10px;">Crea tu nuevo PIN</h2>
        <img :src="require('@/assets/changepw.png')" style="max-width: 40%; margin:auto;" />
        <h2 style="margin-bottom: 10px; text-align:justify;">Necesitamos que crees tu código para poder fichar</h2>
        <p style="text-align: justify;">Con este código de 4 dígitos, a partir de ahora podrás confirmar todas las
          acciones
          de forma rápida: fichar entradas, pausas, ausencias... Puedes utilizar cualquier combinación excepto '0000'.
        </p>
        <div>
          <div @click="mode = 'crearPin'; instrucciones = 'Introduce tu nuevo PIN'" style="background: #409eff21;">
            <el-button style="background-color: #409EFF; border-color: #409EFF" type="primary" circle><font-awesome-icon
                style="font-size:1.5rem;" :icon="['fas', 'lock']" />
            </el-button> <b style="color: #409EFF">Crear nuevo PIN</b>
          </div>
        </div>
      </el-main>
      <el-main id="mainopciones" v-if="mode == 'seleccioninicio' || mode == 'options' || mode == 'fin'"
        :class="{ 'apply-shake': shake }">
        <div id="contenedorCards">
          <div v-if="drawer == false" id="datosusuario">
            <img :style="`${calcularColor(registros[0], 'estado')}`"
              :src="`https://www.gravatar.com/avatar/${datos.email}`" />
            <span>
              <h2>¡Hola, {{ datos.nombre.split(' ')[0] }}!</h2>
              <h3><small><b>{{ horaActual }}</b> — {{ fechaActual }}</small></h3>
              <small v-if="botonFlotante == 'false'" @click="$emit('abrirOptions')"
                :style="`margin-top: 10px; color:${estadoHoras}`"><b>Ajustes</b></small>
            </span>
          </div>
          <template v-if="registros[0]['tipoRegistro'] != 'Fin'">
            <div id="contenedorCronometros">
              <div class="contador">
                <el-progress :width="100" :color="estadoHoras" type="circle" :percentage="porcentajeHoras"
                  :show-text="false"></el-progress>
                <span :style="`color:${estadoHoras}`"><b>{{
    registros[0]['tipoRegistro'] == 'Pausa' ? tiempos.hh_pausa : tiempos.hh }}</b>h</span>
              </div>
              <div class="contador">
                <el-progress :width="100" :color="estadoHoras" type="circle" :percentage="porcentajeMinutos"
                  :show-text="false"></el-progress>
                <span :style="`color:${estadoHoras}`"><b>{{
    registros[0]['tipoRegistro'] == 'Pausa' ? tiempos.mm_pausa : tiempos.mm }}</b>m</span>
              </div>
              <div class="contador">
                <el-progress :width="100" :color="estadoHoras" type="circle" :percentage="porcentajeSegundos"
                  :show-text="false"></el-progress>
                <span :style="`color:${estadoHoras}`"><b>{{
    registros[0]['tipoRegistro'] == 'Pausa' ? tiempos.ss_pausa : tiempos.ss }}</b>s</span>
              </div>
            </div>
          </template>

          <template v-if="mode == 'seleccioninicio'">
            <div class="contenedorSelector" style="margin-bottom: 0px;">
              <div @click="teletrabajo = false, validarRegistro('Inicio')"
                :style="`background: ${datoscolor.colorfondobotones};`">
                <el-button
                  :style="`background-color: ${datoscolor.colorbotones}; border-color: ${datoscolor.colorbotones}`"
                  type="primary" circle><font-awesome-icon :icon="['fas', 'right-to-bracket']" />
                </el-button> <b :style="`color: ${datoscolor.colorbotones}`">Presencial</b>
              </div>
              <div class="green" @click="teletrabajo = true, validarRegistro('Inicio')">
                <el-button type="success" circle><font-awesome-icon style="font-size:1.2rem;"
                    :icon="['fas', 'house']" />
                </el-button><b>Teletrabajo</b>
              </div>
            </div>
            <div v-if="botonFlotante == 'false'" class="contenedorSelector">
              <div class="purple" @click="$emit('verEmpleados')">
                <el-button type="success" circle><font-awesome-icon style="font-size:1.2rem;"
                    :icon="['fas', 'users']" />
                </el-button><b>Ver empleados</b>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="contenedorSelector" style="margin-bottom: 0px;"
              :style="`${registros[0]['tipoRegistro'] != 'Fin' ? '' : 'background: none'}`">
              <div
                v-if="this.registros[0]['tipoRegistro'] == 'Inicio' || this.registros[0]['tipoRegistro'] == 'Reanudacion'"
                @click="drawer = !drawer" class="yellow">
                <el-button type="primary" circle><font-awesome-icon style="font-size:1.5rem;"
                    :icon="['fas', 'pause']" />
                </el-button> <b>Pausa</b>
              </div>
              <div v-if="this.registros[0]['tipoRegistro'] == 'Pausa'"
                @click="teletrabajo = false, validarRegistro('Reanudacion')"
                :style="`background: ${datoscolor.colorfondobotones};`">
                <el-button
                  :style="`width: 50px; height: 50px; background-color: ${datoscolor.colorbotones}; border-color: ${datoscolor.colorbotones}`"
                  type="primary" circle><font-awesome-icon style="font-size:1.5rem;" :icon="['fas', 'play']" />
                </el-button> <b :style="`color: ${datoscolor.colorbotones}`">Reanudar</b>
              </div>
              <div v-if="this.registros[0]['tipoRegistro'] == 'Pausa'" class="green"
                @click="teletrabajo = true, validarRegistro('Reanudacion')">
                <el-button type="success" circle><font-awesome-icon style="font-size:1.2rem;"
                    :icon="['fas', 'house']" />
                </el-button><b>Teletrabajo</b>
              </div>
              <div v-if="this.registros[0]['tipoRegistro'] != 'Fin'" @click="validarRegistro('Fin')" class="red">
                <el-button type="success" circle><font-awesome-icon style="font-size:1.3rem;" :icon="['fas', 'stop']" />
                </el-button> <b>Finalizar</b>
              </div>
              <div v-else>
                <div
                  style="display:flex; flex-direction:column; margin: auto; align-items:center; justify-content:center;">
                  <el-result icon="success" :title="`Ya has finalizado tu jornada`"></el-result>
                </div>
              </div>
            </div>
            <div v-if="botonFlotante == 'false' && !drawer" class="contenedorSelector">
              <div class="purple" @click="$emit('verEmpleados')">
                <el-button type="success" circle><font-awesome-icon style="font-size:1.2rem;"
                    :icon="['fas', 'users']" />
                </el-button><b>Ver empleados</b>
              </div>
            </div>
            <el-collapse-transition>
              <div v-if="drawer == true" class="contenedorSelector pauseList">
                <div v-for="(pause, p) in pauseList" :key="p" @click="validarRegistro(pause.value)" class="yellow"
                  :style="p == pauseList.length - 1 && pauseList.length % 2 != 0 ? 'width: 100%' : ''">
                  <el-button type="primary" circle><i :class="pause.icon"></i>
                  </el-button> <b>{{ pause.label }}</b>
                </div>
                <el-button type="warning" plain icon="el-icon-back" class="botonvolver"
                  @click="drawer = false"><b>Volver
                    atrás</b></el-button>
              </div>
            </el-collapse-transition>
            <div v-if="modoapp == 'development'" class="rectangulotarea" style="border: 1px solid #d5dae8;
    background: white;
    border-radius: 5px;
    margin: 5px;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #071c64;
    font-weight: bold;">
              <div style="display: flex; align-items: center;">
                <svg style="margin-right: 5px; fill: #071c64;" width="30px" id="Capa_2" data-name="Capa 2"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384">
                  <g id="Capa_1-2" data-name="Capa 1">
                    <g>
                      <path
                        d="m348.07,193.31c0,42.28.03,84.57-.01,126.85-.02,22.12-14.66,39.71-36.35,43.95-3.09.6-6.2.61-9.31.61-73.34.01-146.69.03-220.03,0-26.81,0-45.68-18.91-45.68-45.68,0-84.19-.01-168.39,0-252.58,0-22.43,14.4-39.92,36.36-44.33,2.96-.59,5.95-.59,8.94-.59,73.59-.01,147.18-.03,220.78,0,26.33.01,45.29,19.01,45.3,45.29.02,42.16,0,84.32,0,126.48Zm-23.95-.09c0-41.92,0-83.83,0-125.75,0-13.99-8.02-21.99-22.08-21.99-73.1-.01-146.21-.01-219.31,0-14.07,0-22.08,7.99-22.08,21.98,0,83.71,0,167.42,0,251.13,0,14.24,7.96,22.18,22.26,22.19,72.98,0,145.96,0,218.94,0,14.31,0,22.28-7.95,22.28-22.18,0-41.79,0-83.58,0-125.38Z" />
                      <path
                        d="m133.71,274.19c-3.39.07-6.25-1.29-8.65-3.68-6.8-6.79-13.64-13.54-20.36-20.4-4.95-5.05-5.05-12.4-.43-17.13,4.76-4.87,12.13-4.78,17.39.3,3.32,3.2,6.67,6.4,9.77,9.81,1.79,1.97,2.86,1.86,4.69,0,10.41-10.58,20.94-21.05,31.45-31.54,3.71-3.71,8.15-4.79,13.04-2.99,4.63,1.7,7.2,5.25,7.68,10.15.36,3.67-.84,6.9-3.45,9.52-14.2,14.21-28.41,28.4-42.63,42.58-2.31,2.3-5.15,3.45-8.5,3.37Z" />
                      <path
                        d="m133.89,178.37c-3.49.09-6.37-1.23-8.77-3.62-6.89-6.87-13.83-13.69-20.61-20.66-4.85-4.99-4.76-12.52-.04-17.12,4.66-4.55,11.88-4.53,16.85.16,3.44,3.26,6.88,6.54,10.03,10.07,1.97,2.21,3.08,1.82,4.96-.1,10.23-10.4,20.64-20.63,30.92-30.98,3.81-3.84,8.26-5.12,13.31-3.36,8.2,2.86,10.51,13.14,4.31,19.58-7.61,7.91-15.47,15.56-23.23,23.32-6.17,6.17-12.38,12.31-18.53,18.51-2.57,2.59-5.49,4.27-9.19,4.19Z" />
                      <path
                        d="m256.19,162.49c-10.21,0-20.42.03-30.64,0-7.93-.03-13.2-4.94-13.13-12.11.07-7,5.33-11.81,13.06-11.82,20.55-.02,41.1-.03,61.65,0,7.59.01,13,5.03,13.01,11.95.02,6.92-5.34,11.94-12.94,11.98-10.34.05-20.67.01-31.01.01Z" />
                      <path
                        d="m256.06,258.3c-10.21,0-20.42.03-30.64-.01-7.71-.03-12.96-4.88-12.99-11.87-.04-7.17,5.26-12.05,13.19-12.06,20.42-.02,40.85-.02,61.27,0,7.85,0,13.31,5.03,13.25,12.08-.06,6.91-5.42,11.81-13.08,11.85-10.34.05-20.67.01-31.01.01Z" />
                    </g>
                  </g>
                </svg>Hacer nóminas empleados
              </div><span @click="cambiarModo('selecciontarea'); instrucciones = 'Selecciona una tarea para realizar'"
                style="color: white;
    background: #071c64;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    cursor: pointer;">Cambiar de tarea</span>
            </div>
          </template>
        </div>
      </el-main>
      <el-main id="contenedorajustes" v-if="mode == 'opciones'">
        <div class="datosusuario" v-if="token != ''">
          <img :src="`https://www.gravatar.com/avatar/${datos.email}`" />
          <span>
            <h2>{{ datos.nombre }}</h2>
            <h3><small><b>{{ datos.centro }}</b></small></h3>
          </span>
        </div>
        <span @click="cambiarModo(oldmode)" class="botoncerrar"><font-awesome-icon :icon="['fas', 'xmark']" /></span>

        <template v-if="solicitarpin == 'true' && token != ''">
          <h3>Seguridad</h3>
          <div>
            <div @click="mode = 'cambiarPin'; instrucciones = 'Introduce tu nuevo PIN'" style="background: #409eff21;">
              <el-button style="background-color: #409EFF; border-color: #409EFF" type="primary"
                circle><font-awesome-icon style="font-size:1.5rem;" :icon="['fas', 'lock']" />
              </el-button> <b style="color: #409EFF">Cambiar PIN</b>
            </div>
          </div>
        </template>

        <template v-if="numCentros > 0">
          <h3>Centro</h3>
          <div>
            <div @click="seleccionCentro()" style="background: #409eff21;">
              <el-button style="background-color: #409EFF; border-color: #409EFF" type="primary"
                circle><font-awesome-icon style="font-size:1.5rem;" :icon="['fas', 'building-user']" />
              </el-button> <b style="color: #409EFF">Seleccionar centro</b>
            </div>
          </div>
        </template>
        <h3>Sesión</h3>
        <div>
          <div v-if="checkGuardar" @click="checkGuardar = !checkGuardar" style="background: #a3cb3826;">
            <el-button style="background-color: #A3CB38; border-color: #A3CB38" type="primary" circle><font-awesome-icon
                style="font-size:1.5rem;" :icon="['fas', 'floppy-disk']" />
            </el-button> <b style="color: #A3CB38">DNI guardado</b>
          </div>
          <div v-else @click="checkGuardar = !checkGuardar" style="background: #ff475726;">
            <el-button style="background-color: #ff4757; border-color: #ff4757" type="primary" circle><font-awesome-icon
                style="font-size:1.5rem;" :icon="['fas', 'floppy-disk']" />
            </el-button> <b style="color: #ff4757">DNI no guardado</b>
          </div>
          <div @click="mode = 'logout';" style="background: #ff475726;">
            <el-button style="background-color: #ff4757; border-color: #ff4757" type="primary" circle><font-awesome-icon
                style="font-size:1.5rem;" :icon="['fas', 'right-from-bracket']" />
            </el-button> <b style="color: #ff4757">Cerrar sesión</b>
          </div>
        </div>
      </el-main>
      <el-main id="mainselectortarea" v-if="mode == 'selecciontarea'">
        <h2 class="selecciontarea">{{ instrucciones }}</h2>
        <div class="contenedorbusqueda">
          <el-form label-position="top" label-width="100px" status-icon style="margin-bottom: 10px;">
            <el-input type="text" v-model="buscadortareas" placeholder="Escribe para buscar"></el-input>
          </el-form>
        </div>
        <ul class="contenedorreceptores">

        </ul>
      </el-main>
      <el-main v-if="mode == 'centro'"
        style="text-align: center; align-items: center; display: flex; flex-direction: column;">
        <h2 class="instrucciones">{{ instrucciones }}</h2>
        <div :class="`selectorcentro ${centro.horasTrabajo == '00:00:00' ? 'disabled' : 'enabled'}`"
          v-for="(centro, index) in datos" :key="centro.id"
          @click="datos = datos[index]; inicializarDatos(); mode = 'none';">
          <img :src="require('@/assets/empresa.png')" style="margin-right: 20px" width="50px" />
          <span>
            <h3 style="margin: 0px">{{ centro.centro }}</h3>
            <small><b><i class='el-icon-time'></i> {{ centro.horasTrabajo != '00:00:00' ? `Tu jornada hoy es de
                ${centro.horasTrabajo.split(":")[0]}h ${centro.horasTrabajo.split(":")[1]}m` : `No tienes que trabajar
                hoy`}}</b></small>
          </span>
        </div>
      </el-main>
      <el-main v-loading="loading" v-if="mode == 'none' || mode == 'validate'" style="min-height: 10vh">
      </el-main>
      <el-main v-if="mode == 'success'">
        <div style="display:flex; flex-direction:column; margin: auto; align-items:center; justify-content:center;">
          <el-result icon="success"
            :title="`Registro de ${registros[0]['tipoRegistro'].split('::')[0].toLowerCase()} añadido correctamente`"></el-result>
        </div>
      </el-main>
      <el-main style="padding: 0px" v-if="mode == 'verEmpleados'">
        <PanelEmpleados :token="token" :datos="datos" :oldmode="oldmode" @mode="(mode) => cambiarModo(mode)" />
      </el-main>
      <audio id="audiosuccess" controls style="display:none">
        <source type="audio/mp3" src="@/assets/success.mp3">
      </audio>
    </div>
    <div
      style="height: calc(100vh - 200px); display: flex; flex-direction: column; align-items: center; justify-content: center;"
      v-show="ubicacion == 'Error' && requerirubicacion == 'true'">
      <img src="@/assets/ubicacion.png" width="50%" />
      <h2 style="margin-bottom: 0px; width: 75%">No podemos ubicarte</h2>
      <h3 style="margin-top: 5px; width: 75%">Por favor, activa la geolocalización para utilizar fichajes.</h3>
      <p>{{ coordenadasfijas }}</p>
    </div>
  </div>
</template>

<script>
import PanelEmpleados from "@/components/PanelEmpleados.vue";
import {
  VerificarExistenciaEmp,
  validarPin,
  ObtenerRegistros,
  enviarRegFichaje,
  enviarRegFichajeImagen,
  validarTokenRecover,
  cambiarEstado,
} from "@/api.js";
import is from 'is_js'
const moment = require('moment');
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')

export default {
  name: "PinPad",
  components: {
    PanelEmpleados
  },
  data() {
    return {
      salida: [],
      datos: {},
      checkGuardar: false,
      dni: "",
      token: "",
      pin: "",
      mode: "validate",
      oldmode: "",
      loading: false,
      ubicacion: "",
      drawer: false,
      drawerestado: false,
      shake: false,
      selcentro: process.env.VUE_APP_SELCENTRO == 'true' ? true : false,
      version: process.env.VUE_APP_VERSION,
      modoapp: process.env.NODE_ENV,
      solicitarpin: process.env.VUE_APP_PIN,
      requerirubicacion: process.env.VUE_APP_LOCATION,
      coordenadasfijas: process.env.VUE_APP_COORDENADAS,
      registros: [
        {
          tipoRegistro: null,
          hora: "00:00:00",
        },
      ],
      optionSelected: "",
      teletrabajo: false,
      instrucciones: 'Introduce tu DNI',
      activeName: "sesion",
      timeout: "",
      numCentros: 0,
      ausenciaFutura: '',
      horaActual: '',
      fechaActual: '',
      emailRecover: '',
      tokenRecover: '',
      recoverymode: true,
      tiempos: {
        hh: 0,
        mm: 0,
        ss: 0,
        total: '00h 00m 00s',
        hh_pausa: 0,
        mm_pausa: 0,
        ss_pausa: 0,
        total_pausa: '00h 00m 00s',
      },
      buscadortareas: '',
      botones: [
        {
          valor: 1,
          texto: 1,
          tipo: "primary",
        },
        {
          valor: 2,
          texto: 2,
          tipo: "primary",
        },
        {
          valor: 3,
          texto: 3,
          tipo: "primary",
        },
        {
          valor: 4,
          texto: 4,
          tipo: "primary",
        },
        {
          valor: 5,
          texto: 5,
          tipo: "primary",
        },
        {
          valor: 6,
          texto: 6,
          tipo: "primary",
        },
        {
          valor: 7,
          texto: 7,
          tipo: "primary",
        },
        {
          valor: 8,
          texto: 8,
          tipo: "primary",
        },
        {
          valor: 9,
          texto: 9,
          tipo: "primary",
        },
        {
          valor: "Del",
          tipo: "danger",
          icon: "el-icon-back",
        },
        {
          valor: 0,
          texto: 0,
          tipo: "primary",
        },
        {
          valor: "Intro",
          tipo: "success",
          icon: "el-icon-right",
        },
      ],
      pauseSelected: false,
      pauseList: [{
        label: 'Interrupción',
        icon: 'el-icon-coffee-cup',
        value: 'Pausa::Interrupción'
      },
      {
        label: 'Comida',
        icon: 'el-icon-food',
        value: 'Pausa::Comida'
      },
      //  {
      //   label: 'Reunión',
      //   icon: 'el-icon-data-analysis',
      //   value: 'Pausa::Reunión'
      // },
      //  {
      //   label: 'Formación',
      //   icon: 'el-icon-notebook-2',
      //   value: 'Pausa::Formación'
      // }, 
      {
        label: 'Personal',
        icon: 'el-icon-user',
        value: 'Pausa::Personal'
      },
        //  {
        //   label: 'Trabajo',
        //   icon: 'el-icon-suitcase',
        //   value: 'Pausa::Trabajo'
        // }
      ],
      ausenciaList: [
        {
          label: 'Reunión',
          icon: 'el-icon-data-analysis',
          value: 'Ausencia::Reunión'
        }, {
          label: 'Formación',
          icon: 'el-icon-notebook-2',
          value: 'Ausencia::Formación'
        }, {
          label: 'Personal',
          icon: 'el-icon-user',
          value: 'Ausencia::Personal'
        }, {
          label: 'Trabajo',
          icon: 'el-icon-suitcase',
          value: 'Ausencia::Trabajo'
        }
      ],
      solicitarfoto: process.env.VUE_APP_SOLICITARFOTO,
      stream: null,
      photoDataUrl: null,
      videoDevices: [],
      botonFlotante: process.env.VUE_APP_BOTONFLOTANTE,
    };
  },
  props: {
    options: {
      type: Boolean,
      default: false,
    },
    estado: {
      type: String,
      default: '',
    },
    modo: {
      type: String,
      default: '',
    },
    datoscolor: {
      type: Array,
    },
  },
  mqtt: {
    /** 'VueMqtt/publish1' or '+/publish1' */
    async 'funcionamiento/+'(data, topic) {
      data = JSON.parse(data);
      if (data['dni'] == this.dni || !data['dni']) {
        if (topic.split('/').pop() === 'recargar') {
          this.obtenerRegistros()
        }
        if (topic.split('/').pop() === 'mensaje') {
          this.$message({
            message: data['mensaje'],
            type: data['tipo'],
          });
        }
      }
    }
  },
  computed: {
    porcentajeHoras() {
      let tiempo = moment(this.datos['horasTrabajo'], 'HH:mm')
      let horastrabajo = tiempo.hour() * 60 + tiempo.minute();
      let horastrabajadas = this.tiempos.hh * 60 + this.tiempos.mm;
      if (!isNaN((horastrabajadas * 100) / horastrabajo)) {
        if ((horastrabajadas * 100) / horastrabajo > 100) {
          return 100;
        }
        return (horastrabajadas * 100) / horastrabajo;
      }
      return 0;
    },
    porcentajeMinutos() {
      let tiempo = moment(this.datos['horasTrabajo'], 'HH:mm')
      let horastrabajo = tiempo.hours();
      let horastrabajadas = this.tiempos.hh
      if (horastrabajo == horastrabajadas) {
        return (this.tiempos.mm * 100) / tiempo.minute();
      } else {
        return (this.tiempos.mm * 100) / 60;
      }
    },
    porcentajeSegundos() {
      return (this.tiempos.ss * 100) / 60;
    },
    estadoHoras() {
      if (this.porcentajeHoras >= 100) {
        return '#27ae60';
      }
      if (this.registros[0]['tipoRegistro'] == 'Pausa') {
        return 'rgb(249, 202, 36)';
      } else {
        return this.datoscolor.colorbotones;
      }
    }
  },
  mounted() {
    console.log(process.env.NODE_ENV);
    let href = window.location.href.split('/');
    if (href[href.length - 1] == 'escritorio') {
      this.selcentro = true;
    }
    if (localStorage.selcentro == 'true') {
      this.selcentro = true;
    }
    this.init();
    window.addEventListener('keyup', this.teclaPresionada);
    setInterval(() => {
      this.fechaActual = moment().format('D') + ' de ' + moment().format('MMMM');
      this.horaActual = moment().format('HH:mm');
      this.calcularTiempos();
    }, 1000);
  },
  watch: {
    estado() {
      if (this.estado != '') {
        if (this.estado == 'Reunido') {
          this.$prompt('Puedes establecer un comentario, como la ubicación donde celebrarás la reunión.', 'Introduce un comentario', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }).then(({ value }) => {
            if (value && value != '') {
              this.validarRegistro('cambiarEstado::' + this.estado + ': ' + value);
            } else {
              this.validarRegistro('cambiarEstado::' + this.estado);
            }
            this.$emit('limpiarEstado');
          }).catch(() => {
            this.$message({
              type: 'info',
              message: 'Se ha cancelado el cambio de estado'
            });
            this.$emit('limpiarEstado');
          });
        } else {
          this.validarRegistro('cambiarEstado::' + this.estado);
          this.$emit('limpiarEstado');
        }
      }
    },
    options() {
      if (this.options) {
        if (this.mode != 'opciones' && this.mode != 'estado') {
          if (this.mode != 'verEmpleados') {
            this.oldmode = this.mode;
          }
          this.cambiarModo('opciones')
        }
        if (!this.selcentro) {
          this.mode = "logout"
        }
      }
      this.$emit("updateOptions");
    },
    mode() {
      if (this.solicitarpin == 'false' && (this.mode == 'dni' || this.mode == 'pin' || this.mode == 'cambiarPin' || this.mode == 'crearPin')) {
        this.validarDNI();
      }
      if (this.mode == 'logout') {
        if (this.recoverymode) {
          location.href = '/';
        }
        localStorage.dni = '';
        clearTimeout(this.timeout);
        this.loading = true;
        Object.assign(this.$data, this.$options.data())
        this.init();
        this.loading = false;
      }
    },
    async modo() {
      if (this.modo != 'opciones' && this.modo != 'verEmpleados' && this.modo != 'estado') { this.oldmode = this.mode; }
      if (this.modo != this.mode) {
        this.cambiarModo(this.modo);
      }
    },
    checkGuardar() {
      if (this.mode == 'opciones') {
        if (this.checkGuardar) {
          localStorage.dni = this.dni;
          this.$notify({
            title: 'Correcto',
            message: 'Ahora iniciarás sesión de forma automática.',
            type: 'success'
          });
        } else {
          this.$notify({
            title: 'Correcto',
            message: 'Ya no iniciarás sesión de forma automática.',
            type: 'success'
          });
          localStorage.dni = '';
        }
      }
    },
    registros() {
      this.$emit("registros", this.registros);
    },
    salida() {
      if (this.salida.length == 0) {
        this.botones[9]['icon'] = 'el-icon-back';
      } else {
        this.botones[9]['icon'] = 'el-icon-close';
      }
    },
  },
  methods: {
    async initCamera() {
      try {
        this.photoDataUrl = null;
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });

        this.stream = stream;
        const video = this.$refs.video;
        video.srcObject = stream;
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
          navigator.mediaDevices.enumerateDevices()
            .then(devices => {
              this.videoDevices = devices.filter(device => device.kind === 'videoinput');
            })
            .catch(error => {
              console.error('Error al enumerar dispositivos:', error);
            });
        } else {
          console.error('La API de mediaDevices no está soportada en este navegador.');
        }
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    },
    async aceptarFoto() {
      this.loading = true;
      let data = {
        token: this.token,
        tipo: 'Inicio',
        motivo: '',
        solicitarpin: 'false',
        pin: this.pin,
        ubicacion: this.ubicacion,
        teletrabajo: this.teletrabajo,
        solicitarfoto: this.solicitarfoto,
        imagen: this.photoDataUrl,
        dni: this.dni
      };
      let resp = await (await enviarRegFichajeImagen(data));
      console.log(resp);
      if (resp.status == 200) {
        this.salida = this.dni;
        this.mode = 'validate';
        this.validarDNI();
      }
    },
    takePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      // Ajusta el tamaño del canvas al tamaño del video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Captura una imagen del video y la dibuja en el canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convierte el contenido del canvas a una URL de datos
      this.photoDataUrl = canvas.toDataURL('image/png');

      // Detén el streaming para liberar la cámara
      this.stream.getTracks().forEach(track => track.stop());
    },
    // Agrega esta función a tus métodos
    switchCamera() {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            const videoDevices = devices.filter(device => device.kind === 'videoinput');

            if (videoDevices.length > 1) {
              // Cambia al siguiente dispositivo de video
              const currentDeviceId = this.$refs.video.srcObject.getVideoTracks()[0].getSettings().deviceId;
              const nextDevice = videoDevices.find(device => device.deviceId !== currentDeviceId);

              if (nextDevice) {
                const constraints = { video: { deviceId: { exact: nextDevice.deviceId } } };
                navigator.mediaDevices.getUserMedia(constraints)
                  .then(stream => {
                    this.$refs.video.srcObject = stream;
                  })
                  .catch(error => {
                    console.error('Error al cambiar de cámara:', error);
                  });
              }
            }
          })
          .catch(error => {
            console.error('Error al enumerar dispositivos:', error);
          });
      } else {
        console.error('La API de mediaDevices no está soportada en este navegador.');
      }
    },
    calcularColorEstado(empleado) {
      let respuesta = '';
      if (empleado.ultimofichaje.tipoRegistro != null) {
        console.log(empleado);
        if (empleado.estado.includes('Reunido')) {
          empleado.estado = 'Reunido';
        }
        switch (empleado.estado) {
          case 'Disponible':
            respuesta = '#44fc97';
            break;
          case 'Ausente':
            respuesta = '#ffc000';
            break;
          case 'Reunido':
            respuesta = '#48dbfb';
            break;
          case 'Ocupado':
            respuesta = '#ff4757';
            break;
        }
        if (empleado.ultimofichaje['tipoRegistro'] == 'Fin') {
          respuesta = 'white';
        }
        if (empleado.ultimofichaje['tipoRegistro'] == 'Pausa') {
          respuesta = '#ffc000';
        }
      } else {
        respuesta = 'black';
      }
      return respuesta;
    },
    async recuperarPin() {
      let respuesta = await this.enviarRegistro('recuperarPin::' + this.emailRecover);
      this.$notify({
        title: respuesta.titulo,
        message: respuesta.mensaje,
        type: respuesta.tipo
      });
      if (respuesta.status == 200) {
        this.instrucciones = 'Introduce tu DNI'
        this.init();
      }
      this.emailRecover = '';
    },
    async validarTokenRecover() {
      let respuesta = await (await validarTokenRecover({ token: this.tokenRecover }));
      if (respuesta.status == 200) {
        window.history.pushState('', "", "/");
        this.$notify({
          title: respuesta.titulo,
          message: respuesta.mensaje,
          type: respuesta.tipo
        });
        this.salida = respuesta.busqueda.dni.split('');
        this.recoverymode = false;
        this.mode = 'validate';
        this.validarDNI();
      } else {
        window.history.pushState('', "", "/?recovery");
        this.tokenRecover = '';
        this.$notify({
          title: respuesta.titulo,
          message: respuesta.mensaje,
          type: respuesta.tipo
        });
      }
    },
    calcularTiempos() {
      let segundosTotales = 0;
      let segundosPausas = 0;
      // let segundosInterrupcion = 0;
      let hora1, hora2;

      const getLastRegistroHour = () => moment(this.registros[this.registros.length - 1]['hora'], 'HH:mm:ss');
      const calculateSeconds = (h1, h2) => ((h1.hour() * 3600) + (h1.minutes() * 60) + h1.seconds()) - ((h2.hour() * 3600) + (h2.minutes() * 60) + h2.seconds());

      hora1 = moment();
      hora2 = getLastRegistroHour();
      segundosTotales = calculateSeconds(hora1, hora2);

      if (this.registros[0]['tipoRegistro'] == 'Reanudacion') {
        for (let i = 0; i < this.registros.length - 1; i += 2) {
          if (this.registros[i]['tipoRegistro'] == 'Reanudacion') {
            hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
            hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
            segundosTotales -= calculateSeconds(hora1, hora2);
            // if(this.registros[i + 1]['motivo'] == 'Interrupción') {
            // hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
            //   if(calculateSeconds(hora1, hora2) > 900) {
            //     segundosTotales += 900;
            //   } else {
            //     segundosPausas += calculateSeconds(hora1, hora2)
            //   }
            // }
          }
        }
      }

      if (this.registros[0]['tipoRegistro'] == 'Pausa') {
        segundosTotales = 0;
        for (let i = 0; i < this.registros.length; i += 2) {
          if (this.registros[i]['tipoRegistro'] == 'Pausa') {
            hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
            hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
            segundosTotales += calculateSeconds(hora1, hora2);
            hora1 = moment();
            hora2 = getLastRegistroHour();
            segundosPausas = ((hora1.hour() * 3600) + (hora1.minutes() * 60) + hora1.seconds()) - ((hora2.hour() * 3600) + (hora2.minutes() * 60) + hora2.seconds()) - segundosTotales;
            // if(this.registros[i]['motivo'] == 'Interrupción') {
            //   hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
            // hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
            // console.log(calculateSeconds(hora1, hora2));
            //   if(calculateSeconds(hora1, hora2) > 900) {
            //     segundosInterrupcion + 900;
            //   } else {
            //     segundosPausas -= calculateSeconds(hora1, hora2)
            //   }
            // }
          }
        }
      }

      if (this.registros[0]['tipoRegistro'] == 'Fin') {
        clearInterval(this.interval);
        segundosTotales = 0;
        for (let i = 0; i < this.registros.length - 1; i += 2) {
          hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
          hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
          segundosTotales += calculateSeconds(hora1, hora2);
        }
        hora1 = moment(this.registros[0]['hora'], 'HH:mm:ss');
        hora2 = getLastRegistroHour();
        segundosPausas = calculateSeconds(hora1, hora2) - segundosTotales;
      }

      this.tiempos.hh = Math.floor(segundosTotales / 3600);
      this.tiempos.mm = Math.floor((segundosTotales % 3600) / 60);
      this.tiempos.ss = segundosTotales % 60;
      this.tiempos.hh_pausa = Math.floor(segundosPausas / 3600);
      this.tiempos.mm_pausa = Math.floor((segundosPausas % 3600) / 60);
      this.tiempos.ss_pausa = segundosPausas % 60;
      this.tiempos.total = `${this.tiempos.hh.toString().padStart(2, '0')}h ${this.tiempos.mm.toString().padStart(2, '0')}m ${this.tiempos.ss.toString().padStart(2, '0')}s`;

      if (isNaN(this.tiempos.hh_pausa)) this.tiempos.hh_pausa = this.tiempos.hh;
      if (isNaN(this.tiempos.mm_pausa)) this.tiempos.mm_pausa = this.tiempos.mm;
      if (isNaN(this.tiempos.ss_pausa)) this.tiempos.ss_pausa = this.tiempos.ss;
      this.tiempos.total_pausa = `${this.tiempos.hh_pausa.toString().padStart(2, '0')}h ${this.tiempos.mm_pausa.toString().padStart(2, '0')}m ${this.tiempos.ss_pausa.toString().padStart(2, '0')}s`;

      if (!this.registros[0]['tipoRegistro']) {
        this.tiempos.hh = 0;
        this.tiempos.mm = 0;
        this.tiempos.ss = 0;
        this.tiempos.total = '00h 00m 00s';
      }
    },
    init() {
      if (localStorage.selcentro == 'true') {
        this.selcentro = true;
      }
      let datoscentro = {
        selcentro: this.selcentro,
        logo: process.env.VUE_APP_LOGO,
        centro: process.env.VUE_APP_CENTRO,
        version: this.version,
      }
      this.$emit('datoscentro', datoscentro);
      let datos = {
        bienvenida: "Bienvenid@"
      }
      this.$emit("finish", datos);
      let params = new URLSearchParams(window.location.search);
      let dni = params.get("dni");
      if (!dni && this.solicitarpin == 'false') {
        return;
      }
      let param = window.location.href.split('/').pop();
      console.log(param);
      if (localStorage.dni) {
        this.checkGuardar = true;
        dni = localStorage.dni;
        this.mode = 'validate';
      } else if (!dni) {
        this.cambiarModo("dni")
      }
      if (param == '?recovery' || params.get('recovery')) {
        let recovery = params.get('recovery');
        console.log(recovery);
        this.mode = 'recovery';
        this.cambiarModo('recovery');
        if (recovery) {
          this.tokenRecover = recovery;
          this.validarTokenRecover();
          this.recoverymode = true;
        }
      }
      if ((dni) && this.mode == "validate") {
        this.salida = dni;
        this.validarDNI();
      }
      this.$mqtt.subscribe('funcionamiento/+')
      this.getLocation()
    },
    teclaPresionada(e) {
      if (e.key == 'F2') {
        localStorage.selcentro = !this.selcentro;
        this.selcentro = !this.selcentro;
        if (this.selcentro) {
          this.$notify({
            title: 'Correcto',
            message: 'Se ha desactivado el modo estático',
            type: 'success'
          });
        } else {
          this.$notify({
            title: 'Correcto',
            message: 'Se ha activado el modo estático',
            type: 'success'
          });
        }
      }
      if (this.mode == 'dni' || this.mode == 'pin' || this.mode == 'crearPin' || this.mode == 'cambiarPin') {
        if (!isNaN(e.key) && e.key <= 9 && e.key != '0') {
          this.anadirContenido(this.botones[e.key - 1]);
        }
        if (e.key == "Enter") {
          this.anadirContenido(this.botones[11]);
        }
        if (e.key == "Backspace") {
          this.anadirContenido(this.botones[9]);
        }
        if (e.key == '0') {
          this.anadirContenido(this.botones[10]);
        }
      }
    },
    shakeAnimation() {
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 820); // timeout value depending on the duration of the animation
    },
    getLocation() {
      if (this.coordenadasfijas) {
        this.ubicacion = this.coordenadasfijas;
        return;
      }
      if (!("geolocation" in navigator)) {
        return alert("Tu navegador no soporta el acceso a la ubicación. Intenta con otro");
      }

      this.ubicacion = "Cargando...";

      const obtenerUbicacion = () => {
        navigator.geolocation.getCurrentPosition(
          ubicacion => {
            const coordenadas = ubicacion.coords;
            this.ubicacion = coordenadas.latitude + ", " + coordenadas.longitude;
          },
          err => {
            console.log(err);
            this.ubicacion = "Error";
          },
          {
            enableHighAccuracy: true, // Alta precisión
            maximumAge: 0, // No queremos caché
            timeout: 5000 // Esperar solo 5 segundos
          }
        );
      };

      // Verificar si la geolocalización ya se solicitó previamente
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' })
          .then(permissionStatus => {
            if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
              // Ya se concedió permiso o se está solicitando por primera vez
              obtenerUbicacion();
            } else {
              this.ubicacion = "Error";
            }
          })
          .catch(err => {
            console.log(err);
            this.ubicacion = "Error";
          });
      } else {
        obtenerUbicacion();
      }
    },
    async seleccionCentro() {
      this.loading = true;
      let dni = this.dni;
      let checkGuardar = this.checkGuardar;
      Object.assign(this.$data, this.$options.data())
      this.checkGuardar = checkGuardar;
      this.salida = dni.split("");
      this.mode = "validate";
      await this.validarDNI();
      this.loading = false;
    },
    anadirContenido(boton) {
      if (isNaN(boton.valor)) {
        if (boton.valor == "Del") {
          if (this.optionSelected.split('::')[0] == 'crearPin') {
            this.mode = 'nuevoPin';
          }
          if (this.mode == "pin" && this.salida.length == 0 && this.registros[0]['tipoRegistro'] != null) {
            this.cambiarModo("options")
            this.instrucciones = 'Selecciona una opción';
          } else if (this.mode == "pin" && this.salida.length == 0 && this.registros[0]['tipoRegistro'] == null) {
            this.cambiarModo("seleccioninicio")
          }
          else if (this.mode == "cambiarPin" && this.salida.length == 0) {
            this.cambiarModo("opciones")
          }
          else if (this.mode == "crearPin" && this.salida.length == 0) {
            this.cambiarModo("nuevoPin")
          }
          else {
            this.salida = []
          }
        } else if (boton.valor == "Intro") {
          this.validarDNI();
        }
      } else {
        if (this.salida.length < 8) {
          this.salida.push(boton.valor);
        } else {
          this.$message({
            message: "Has llegado al límite de caracteres permitidos.",
            type: "error",
          });
        }
      }
    },
    async inicializarDatos() {
      this.token = this.datos.token
      await this.obtenerRegistros()
      if (this.solicitarpin == 'true' && this.datos.cambiarPin == true) {
        this.instrucciones = "Introduce tu nuevo PIN"
        this.cambiarModo("nuevoPin");
      } else {
        if (this.registros[0]['tipoRegistro'] == null) {
          this.cambiarModo("seleccioninicio")
          this.instrucciones = "Selecciona el tipo de inicio"
        } else {
          this.cambiarModo("options")
          this.instrucciones = 'Selecciona una opción';
          if (this.registros[0]['tipoRegistro'] == 'Fin' && !this.selcentro) {
            this.timeout = setTimeout(() => {
              this.mode = 'logout';
            }, 5000);
          }
        }
        let datos = {
          bienvenida: "Hola, " + this.datos.nombre.split(' ')[0],
          email: this.datos.email,
          horasTrabajo: this.datos.horasTrabajo,
          motivoAusenciaFutura: this.datos.motivoAusenciaFutura,
          logo: this.datos.logo,
        }
        this.datos.tiempoTrabajo = this.datos.horasTrabajo.split(':')[0].toString().padStart(2, '0') + 'h ' + this.datos.horasTrabajo.split(':')[1].toString().padStart(2, '0') + 'm ' + this.datos.horasTrabajo.split(':')[2].toString().padStart(2, '0') + 's';
        this.$emit("finish", datos);
      }
    },
    async validarDNI() {
      this.loading = true;
      if (this.mode == "dni" || this.mode == "validate") {
        if (typeof (this.salida) === "object") {
          this.salida = this.salida.join("");
        }
        if (this.salida.length == 7 || this.salida.length == 8) {
          let data = {
            'dni': this.salida,
            'centro': process.env.VUE_APP_SELCENTRO == 'false' ? process.env.VUE_APP_CENTRO : null
          }
          let resp = await (await VerificarExistenciaEmp(data))
          if (resp) {
            this.datos = resp.datosemp;
            if (resp.tipo == 'success') {
              if (resp.datosemp[0].fichajemovil == 0 && is.not.windows()) {
                alert('Lo sentimos, no se permite fichar desde un dispositivo móvil.');
                this.loading = false;
                return;
              }
              if (resp.datosemp[0].fichajemovil == 1 && is.not.windows()) {
                this.requerirubicacion = 'true';
              }
              let datos = {
                bienvenida: "Hola, " + resp.datosemp[0].nombre.split(' ')[0],
                email: resp.datosemp[0].email,
              }
              this.$emit("finish", datos);
              this.$emit("estado", resp.datosemp[0].estado);
              this.dni = this.salida;
              if (this.checkGuardar) {
                localStorage.dni = this.salida;
              }
              if (resp.datosemp.length == 1) {
                this.datos = resp.datosemp[0];
                this.inicializarDatos();
              }
              if (resp.datosemp.length > 1) {
                this.cambiarModo("centro")
                this.instrucciones = "Selecciona un centro"
                this.numCentros = resp.datosemp.length;
              }
            } else {
              this.shakeAnimation();
              this.$message({
                message: resp.mensaje,
                type: resp.tipo,
              });
            }
          } else {
            this.cambiarModo("dni");
            this.shakeAnimation();
            this.$message({
              message: "No se ha establecido conexión con el servidor",
              type: "error",
            });
          }
        } else {
          this.shakeAnimation();
        }
        this.salida = [];
      } else if (this.mode == 'pin') {
        let optionSelected = this.optionSelected ? this.optionSelected : '';
        this.loading = true;
        this.pin = this.salida.join("");
        let data = {
          token: this.token,
          pin: this.pin,
          optionSelected
        }
        let resp = await (await validarPin(data));
        if (resp || this.solicitarpin == 'false') {
          if (this.solicitarpin == 'true' && resp.status == 400) {
            this.shakeAnimation();
          } else {
            if (this.optionSelected) {
              await (await this.enviarRegistro(this.optionSelected))
            }
            if (this.solicitarfoto == 'true' && this.optionSelected == 'Inicio') {
              this.cambiarModo('solicitarFoto');
              this.initCamera();
              return;
            }
            if (this.registros[0]['tipoRegistro'] == null && this.optionSelected == '') {
              await (await this.enviarRegistro('Inicio'))
            }
            if (this.selcentro) {
              if (this.registros[0]['tipoRegistro'] == null) {
                this.cambiarModo("seleccioninicio")
              } else {
                this.cambiarModo("options")
              }
            }
            this.instrucciones = 'Selecciona una opción';
          }
        } else {
          this.$message({
            message: "No se ha establecido conexión con el servidor",
            type: "error",
          });
        }
        this.loading = false;
        this.salida = [];
      }
      else if (this.mode == 'cambiarPin') {
        this.loading = true;
        this.validarRegistro("cambiarPin::" + this.salida.join(""));
        this.instrucciones = "Introduce tu antiguo PIN para confirmar"
        this.loading = false;
        this.salida = [];
      }
      else if (this.mode == 'crearPin') {
        this.loading = true;
        this.validarRegistro("crearPin::" + this.salida.join(""));
        this.instrucciones = "Confirma tu nuevo PIN"
        this.loading = false;
        this.salida = [];
      }
      this.loading = false;
    },
    validarRegistro(tipo) {
      this.drawer = false;
      this.drawerestado = false;
      this.optionSelected = tipo;
      this.cambiarModo("pin");
      this.instrucciones = 'Introduce el PIN para continuar';
    },
    async obtenerRegistros() {
      this.loading = true;
      let data = {
        token: this.token,
        pin: this.pin,
      };
      this.loading = true;
      let registros = await (await ObtenerRegistros(data));
      if (registros.length > 0) {
        this.registros = registros;
      }
      this.$emit("registros", this.registros);
      this.loading = false;
    },
    async enviarRegistro(tipo) {
      this.loading = true;
      if (tipo.includes("::")) {
        tipo = tipo.split("::")
        var motivo = tipo[1]
        tipo = tipo[0]
      }
      if (tipo == 'Ausencia') {
        this.datos.motivoAusenciaFutura = motivo;
      } else {
        this.datos.motivoAusenciaFutura = 'nada';
      }
      let datos = {
        bienvenida: "Hola, " + this.datos.nombre.split(' ')[0],
        email: this.datos.email,
        horasTrabajo: this.datos.horasTrabajo,
        motivoAusenciaFutura: this.datos.motivoAusenciaFutura,
        logo: this.datos.logo,
      }
      this.$emit("finish", datos);
      if (!this.selcentro && tipo != 'crearPin') {
        this.success();
      } else if (tipo == 'crearPin') {
        if (this.registros[0]['tipoRegistro'] == null) {
          this.cambiarModo('seleccioninicio');
        } else {
          this.cambiarModo('options');
        }
      }
      try {
        let data = {
          token: this.token,
          tipo: tipo,
          motivo: motivo,
          solicitarpin: this.solicitarpin,
          pin: this.pin,
          ubicacion: this.ubicacion,
          teletrabajo: this.teletrabajo,
          solicitarfoto: this.solicitarfoto
        };
        let resp
        if (data.tipo != 'cambiarEstado') {
          resp = await (await enviarRegFichaje(data));
        } else {
          resp = await (await cambiarEstado(data));
          if (resp.tipo == 'success') {
            this.$emit("estado", resp.estado);
          }
        }
        let recarga = {
          dni: this.dni
        }
        await this.obtenerRegistros()
        this.$mqtt.publish('funcionamiento/recargar', JSON.stringify(recarga))
        this.teletrabajo = false;
        this.loading = false;
        return resp;
      } catch (e) {
        this.loading = false;
      }
    },
    success() {
      if (!this.selcentro) {
        document.getElementById('audiosuccess').play();
        this.cambiarModo("success")
        this.timeout = setTimeout(() => {
          this.mode = 'logout';
        }, 5000);
      }
    },
    cambiarModo(modo) {
      this.mode = "none";
      setTimeout(() => {
        this.mode = modo;
        this.$emit("mode", this.mode);
      }, 100)
    },
    calcularColor(data, tipo) {
      let respuesta = 'background-color: black; color: white;';
      !data.ultimofichaje ? data.ultimofichaje = { tipoRegistro: null } : ''
      switch (data.ultimofichaje.tipoRegistro) {
        case 'Inicio':
        case 'Reanudacion':
          tipo == 'fondo' ? respuesta = 'background-color: ' + this.calcularColorEstado(data) + '; color: white;' : respuesta = 'color: ' + this.calcularColorEstado(data) + '';
          if (tipo == 'estado') {
            respuesta = 'box-shadow: 0px 0px 10px 0px ' + this.calcularColorEstado(data) + ';';
          }
          if (data.ultimofichaje.teletrabajo) {
            tipo == 'fondo' ? respuesta = 'background-color: #8dc26c; color: white;' : respuesta = 'color: #8dc26c';
            if (tipo == 'estado') {
              respuesta = 'box-shadow: 0px 0px 10px 0px #8dc26c;';
            }
          }
          break;
        case 'Pausa':
          tipo == 'fondo' ? respuesta = 'background-color: #ffc000; color: white;' : respuesta = 'color: #ffc000';
          if (tipo == 'estado') {
            respuesta = 'box-shadow: 0px 0px 10px 0px #ffc000;';
          }
          break;
        case 'Fin':
          tipo == 'fondo' ? respuesta = 'background-color: white; color: black;' : respuesta = 'color: #FCFCFC';
          break;
        default:
          tipo == 'fondo' ? respuesta = 'background-color: black; color: white;' : respuesta = 'color: #000';
          break;
      }
      return respuesta;
    },
    calcularEstado(empleado) {
      let respuesta = 'Inactivo';
      if (empleado.ultimofichaje) {
        switch (empleado.ultimofichaje.tipoRegistro) {
          case 'Inicio':
          case 'Reanudacion':
            respuesta = empleado.estado;
            break;
          case 'Pausa':
            respuesta = 'Pausa (' + empleado.ultimofichaje.motivo + ')';
            break;
          case 'Fin':
            respuesta = 'Fin de jornada';
            break;
          case null:
            respuesta = 'Inactivo';
            break;
        }
      }

      return respuesta;
    }
  }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap');

#mainpinpad {
  text-align: center;

  .instrucciones {
    margin: 10px;
    transition: 0.5s;

    &.red {
      color: rgba(255, 0, 0, 0.699);
    }
  }

  .avatar {
    border-radius: 50%;
    margin: 10px;
    background: white;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 3px;

    @media(max-width: 990px) {
      display: none;
    }
  }

  #contenedorSalida {
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 5px;
    min-height: 50px;
    border: 1px solid #e9e9e9;
    width: 270px;
    margin-bottom: 25px;
    padding: 0px 10px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 30px;
      font-size: 1.8rem;
      margin: 2px;
      padding: 4px;
    }
  }

  .dni-switch {
    margin: 30px 0px;
  }

  .el-switch__label.is-active {
    color: #000000;
  }
}

#mainopciones {
  text-align: center;

  #contenedorCards {
    width: 50%;
    margin: auto;
    transition: 0.5s;

    @media (max-width: 1600px) {
      width: 75%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    #datosusuario {
      display: flex;
      padding: 10px;
      background: #ffffffb8;
      border: 3px solid #d3d3d361;
      border-radius: 10px;
      margin: auto;
      margin-bottom: 10px;
      justify-content: flex-start;
      transition: 0.5s;

      @media (min-width: 990px) {
        display: none
      }

      img {
        border-radius: 50%;
        margin-bottom: 10px;
        padding: 3px;
        margin: 10px 20px 10px 10px;
      }

      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        h2 {
          font-weight: normal;
          margin: 0;
          margin-bottom: 0px;
        }

        h3 {
          font-weight: normal;
          margin: 0;
        }
      }
    }

    #contenedorCronometros {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .contador {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 50%;

        span {
          position: absolute;
          font-size: 2rem;
          transition: 1s;
        }
      }
    }

    .contenedorSelector {
      display: flex;
      background: #ffffff;
      border-radius: 10px;
      margin: auto;
      justify-content: center;
      margin-bottom: 10px;

      &.pauseList {
        flex-wrap: wrap;

        div {
          width: calc(50% - 30px);
        }

        .botonvolver {
          width: 100%;
          font-size: 1rem;
          margin: 5px;
          background-color: rgba(249, 202, 36, 0.15);
          border: none;
          color: rgb(249, 202, 36);
        }
      }

      div {
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px;

        button {
          width: 50px;
          height: 50px;

          span {
            i {
              font-size: 1.5rem;
            }

            svg {
              font-size: 1.5rem;
            }
          }
        }

        &.green {
          background: #a3cb3826;
          color: #A3CB38;

          button {
            background-color: #A3CB38;
            border-color: #A3CB38;
          }
        }

        &.yellow {
          background: #f9ca2426;
          color: #f9ca24;

          button {
            background-color: #f9ca24;
            border-color: #f9ca24;
          }
        }

        &.purple {
          background: #dfe6e9;
          color: #636e72;

          button {
            background-color: #636e72;
            border-color: #636e72;
          }
        }

        &.red {
          background: #ff475726;
          color: #ff4757;

          button {
            background-color: #ff4757;
            border-color: #ff4757;
          }
        }
      }
    }
  }
}

#mainselectortarea {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .contenedorbusqueda {
    @media(min-width: 480px) {
      width: 400px;
    }
  }

  .contenedorreceptores {
    @media(min-width: 480px) {
      width: 400px;
    }

    padding:10px;
    max-height: 50vh;
    overflow-y:auto;

    li {
      list-style: none;
      padding: 10px;
      background: #d5dae8;
      margin: 0px 0px 10px 0px;
      border-radius: 5px;
      color: #071c64;
      cursor: pointer;
      transition: 0.3s;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        fill: #071c64;
        transition: 0.3s;
      }

      &:hover {
        background: #071c64;
        color: white;

        svg {
          fill: white;
        }
      }

      &:active {
        background: #041038;
        color: white;
      }
    }

  }
}

#contenedorajustes {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: left;
  width: 50%;
  background: white;
  margin: auto;
  border-radius: 5px;
  position: relative;

  @media (max-width: 1600px) {
    width: 75%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  .datosusuario {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    justify-content: flex-start;

    img {
      border-radius: 50%;
      margin-bottom: 10px;
      padding: 3px;
      margin: 10px 20px 10px 10px;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      h2 {
        font-weight: normal;
        margin: 0;
        margin-bottom: 0px;
      }

      h3 {
        font-weight: normal;
        margin: 0;
      }
    }
  }

  h3 {
    margin: 10px 0px 0px 0px;
  }

  div {
    display: flex;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 10px;
    justify-content: center;
    margin-bottom: 10px;

    div {
      border-radius: 5px;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 5px;
      transition: 0.5s;

      button {
        width: 50px;
        height: 50px;
        transition: 0.5s;
      }
    }
  }
}

.botoncerrar {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  border: 2px solid darkgray;
  color: darkgray;
  border-radius: 50%;
  font-size: 1.5rem;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  align-items: center;
  transition: 0.5s;

  &:hover {
    background: darkgray;
    color: white;
  }

  &:active {
    transition: 0s;
    background: rgb(104, 104, 104);
    color: white;
  }
}

h2 {
  color: #727272;
  font-weight: 500;
  margin-bottom: 50px;
}


.tarjetaempleado {
  width: 160px;
  height: 160px;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

ul.contenedorNumeros {
  display: flex;
  padding: 0px;
  margin: 0 auto;
  list-style: none;
  flex-wrap: wrap;
  max-width: 300px;
  justify-content: center;

  li {
    width: 80px;
    height: 80px;
    background-color: #f9f9f9;
    border: 1px solid #dedede;
    border-radius: 50%;
    display: flex;
    align-items: Center;
    justify-content: center;
    font-size: 1.5rem;
    margin: 5px;
    color: #585858;
    font-weight: 500;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      background-color: #dedede;
      border: 1px solid #a0a0a0;
    }

    &.danger {
      color: #F56C6C;
      background-color: rgb(253, 226, 226);
      border-color: #F56C6C;

      &:hover {
        background-color: #F56C6C;
        color: rgb(253, 226, 226);
      }
    }

    &.success {
      color: #67C23A;
      background-color: rgb(225, 243, 216);
      border-color: #67C23A;

      &:hover {
        color: rgb(225, 243, 216);
        background-color: #67C23A;
      }
    }
  }
}

.selectorcentro {
  width: 60%;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: #f9f9f9;
  border: 1px solid #dedede;
  color: #585858;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  transition: 1s;
  cursor: pointer;

  &.enabled {
    &:hover {
      background-color: #dedede;
      border-color: #f9f9f9;
    }
  }

  &.disabled {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    cursor: not-allowed;
  }
}

h1 {
  margin: auto;
  color: gray;
}

.selectorestado {
  border-radius: 50%;
  border: 3px solid transparent;
  background: rgba(0, 0, 0, 0.5);
  width: 50px;
  height: 50px;
  position: relative;
  opacity: 0;
  transition: 0.3s;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  &.selected {
    opacity: 1;
  }

  &:active {
    background: rgba(0, 0, 0, 0.5);
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  opacity: 0;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.795);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  z-index: 99999;
  width: 180px;
  top: 150px;
  left: 50%;
  margin-left: -90px;
  transition: 0.5s;
  /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  opacity: 1;
}

.el-loading-mask {
  background-color: none;
}
</style>